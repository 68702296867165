
import { defineComponent, inject } from 'vue';
import Keycloak from 'keycloak-js';

export default defineComponent({
  name: 'AdminPanel',
  setup() {
    const keycloak = inject('keycloak') as Keycloak.KeycloakInstance;
    const baseUrl = process.env.VUE_APP_API_URL.split('/api')[0] as string;

    window.location.href = `${baseUrl}/admin/authenticate?auth_token=${keycloak.token}`;
  },
});
